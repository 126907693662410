.device {
  color: $body-color;
  background: #fff;
  display: flex;
  cursor: pointer;
  border-color: $primary;
  border-bottom: none !important;
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0%);
  position: relative;

  .device-status-icons {
    transition: box-shadow 0.15s ease-in-out;
  }

  .drag-icon {
    top: 5px;
    right: 5px;
    display: none;
    align-items: center;
    position: absolute;
    background-color: transparent;
    opacity: 0.75;
  }

  &:hover {
    .drag-icon {
      display: unset;
    }
  }

  &.warning {
    border-color: $warning;
    background-color: rgba($warning, 10%);

    .device-icon-wrapper {
      background-color: $warning;
      color: $white;
    }
  }

  &.error {
    border-color: $danger;
    background-color: rgba($danger, 10%);

    .device-icon-wrapper {
      background-color: $danger;
      color: $white;
    }
  }

  &.unknown {
    border-color: $gray-600;
    background-color: rgba($gray-600, 10%);

    .device-icon-wrapper {
      background-color: $gray-600;
      color: $white;
    }
  }

  &.success {
    border-color: $green;
    background-color: rgba($green, 10%);

    .device-icon-wrapper {
      background-color: $green;
      color: $white;
    }
  }

  &.selected {
    transform: scale(1.03);
    border-color: $primary;
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 10%);

    .device-status-icons {
      box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 10%);
    }
  }

  &.template {
    cursor: auto;

    &:hover {
      background-color: inherit;
    }
  }

  .badge {
    &.badge-danger {
      color: $white !important;
    }
  }
}
