.sidebar {
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  transition: $sidebar-transition;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 1;
  border-right: none;

  .sidebar-content {
    border-right: none !important;
    z-index: 100;
  }

  .sidebar-item {
    transition: padding 0.1s linear;
    padding: 0 16px;

    .sidebar-link {
      font-size: 14px;
      align-items: center;
      width: 100%;
      padding-bottom: 15px;
      padding-top: 15px;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;

      svg {
        height: 16px !important;
        width: 16px !important;
        transform: translateY(-2px);
        margin-right: 34px !important;
      }
    }
  }

  .sidebar-link:hover,
  .sidebar-link.active {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0 8px 8px 8px;
  }

  .sidebar-dropdown {
    .sidebar-item {
      padding: 0;

      .sidebar-link {
        padding-left: 75px;
      }
    }
  }

  .sidebar-dropdown .sidebar-item .sidebar-link:hover {
    background: $sidebar-link-child-active-bg-partner;
  }

  .sidebar-dropdown .sidebar-item.active .sidebar-link {
    background: $sidebar-link-child-active-bg-partner;
  }

  &:not(.toggled) {
    .sidebar-header {
      opacity: 0;
    }

    .sidebar-item {
      padding: 0 !important;

      .sidebar-link {
        border-radius: 0 !important;
      }
    }
  }

  &.toggled {
    .sidebar-header {
      opacity: 1;
    }
  }
}

.sidebar.organization {
  .sidebar-content {
    background: $sidebar-bg-organization;
  }

  .sidebar-link,
  a.sidebar-link {
    background: $sidebar-bg-organization;
    color: $sidebar-link-color-organization;

    i,
    svg {
      color: $sidebar-link-color-organization;
    }
  }

  .sidebar-link:hover {
    background: $sidebar-link-hover-bg-organization;
    color: $sidebar-link-hover-color-organization;

    i,
    svg {
      color: $sidebar-link-hover-color-organization;
    }
  }

  .sidebar-item.active > .sidebar-link,
  .sidebar-item.active .sidebar-link:hover {
    background: $sidebar-link-active-bg-organization;
    color: $sidebar-link-icon-active-color-organization;
    font-weight: 500;

    i,
    svg {
      color: darken($sidebar-link-icon-active-color-organization, 10);
    }
  }

  .sidebar-dropdown .sidebar-link {
    background: $sidebar-link-child-bg-organization;
  }

  .sidebar-brand {
    a {
      color: $white;
    }

    .sidebar-brand-by-label {
      color: $gray-500;
    }
  }

  .sidebar-header {
    color: $sidebar-header-color-organization;
  }
}

.sidebar.partner {
  .sidebar-content {
    background: $sidebar-bg-partner;
  }

  .sidebar-link,
  a.sidebar-link {
    background: $sidebar-link-bg-partner;
    color: $sidebar-link-color-partner;

    i,
    svg {
      color: $sidebar-link-color-partner;
    }
  }

  .sidebar-link:hover {
    background: $sidebar-link-hover-bg-partner;
    color: $sidebar-link-hover-color-partner;

    i,
    svg {
      color: $sidebar-link-hover-color-partner;
    }
  }

  .sidebar-item.active > .sidebar-link,
  .sidebar-item.active .sidebar-link:hover {
    background: $sidebar-link-active-bg-partner;
    color: darken($sidebar-link-icon-active-color-partner, 10);
    font-weight: 500;

    i,
    svg {
      color: darken($sidebar-link-icon-active-color-partner, 10);
    }
  }

  .sidebar-dropdown .sidebar-link {
    background: $sidebar-link-child-bg-partner;
  }

  .sidebar-brand {
    a {
      color: $black;
    }
  }

  .sidebar-header {
    color: $sidebar-header-color-partner;
  }
}

.sidebar-sticky {
  .sidebar-nav {
    padding-bottom: 0;
  }

  .sidebar-content {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: $sidebar-width;
  }
}

.sidebar-nav {
  padding-bottom: $spacer * 3.5;
  padding-left: 0;
  list-style: none;
}

// Sidebar links
.sidebar-link,
a.sidebar-link {
  display: block;
  padding: $sidebar-link-padding;
  font-weight: $sidebar-link-font-weight;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

// Sidebar nested links
.sidebar-dropdown {
  .sidebar-dropdown {
    .sidebar-link {
      padding: $sidebar-link-child-second-padding;
    }

    .sidebar-dropdown {
      .sidebar-link {
        padding: $sidebar-link-child-third-padding;
      }
    }
  }
}

.sidebar-link:focus {
  outline: 0;
}

// Children
.sidebar-dropdown .sidebar-link {
  padding: $sidebar-link-child-padding;
  color: $sidebar-link-child-color;
  font-weight: $sidebar-link-child-font-weight;
}

.sidebar-dropdown .sidebar-item .sidebar-link:hover {
  color: $sidebar-link-child-hover-color;
  font-weight: $sidebar-link-hover-font-weight;
}

.sidebar-dropdown .sidebar-item.active .sidebar-link {
  color: $sidebar-link-child-active-color;
  font-weight: $sidebar-link-active-font-weight;
}

// Chevron
.sidebar [data-toggle='collapse'] {
  position: relative;
}

.sidebar [data-toggle='collapse']:before {
  content: ' ';
  border: solid;
  border-width: 0 0.1rem 0.1rem 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
}

.sidebar [aria-expanded='true']:before,
.sidebar [data-toggle='collapse']:not(.collapsed):before {
  transform: rotate(-135deg);
  top: 1.4rem;
}

// Sidebar brand
.sidebar-brand {
  font-size: $sidebar-brand-font-size;
  padding: 0 $sidebar-brand-padding-x;
  display: block;
  margin-top: 0;
  padding-top: 45px !important;
  margin-bottom: 20px;
  padding-left: 36px;

  a {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;

    &:hover {
      text-decoration: none !important;
    }

    &:focus {
      outline: 0 !important;
    }

    .sidebar-brand-by-label {
      font-weight: 500;
    }

    .brand-name {
      word-break: break-word;
    }

    svg,
    .feather {
      color: $sidebar-brand-icon-color;
      height: 24px;
      width: 24px;
      margin-right: 27px;
    }
  }
}

// Sidebar header
.sidebar-header {
  padding: $sidebar-header-padding;
  padding-left: 41px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 12px;
  font-weight: bold;
  transition: opacity 0.15s ease-in-out;
}

// Badge
.sidebar-item {
  .badges {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
