.env-info {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 0px;

  .name {
    padding: 8px;
    width: 200px;
    text-align: center;
    margin: auto;
    border-radius: 0 0 20px 20px;
  }
}
