.btn-pill {
  border-radius: 10rem;
}

.btn-square {
  border-radius: 0;
}

.btn .feather {
  width: 14px;
  height: 14px;
}

.btn-lg .feather {
  width: 15px;
  height: 15px;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-color($white);
    @include button-color-hover($white);
  }

  .btn-outline-#{$color} {
    @include button-color-hover($white);
    @include button-color-active($white);
  }
}

@each $color, $value in $social-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    @include button-color($white);
    @include button-color-hover($white);
  }
}

.btn-light,
.btn-white {
  @include button-color($gray-800);
  @include button-color-hover($gray-800);
}

.btn-outline-light,
.btn-outline-white {
  @include button-color-hover($gray-800);
  @include button-color-active($gray-800);
}

.btn-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.btn:disabled {
  cursor: not-allowed;
}

.btn.link {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.btn {
  &.btn-large {
    padding: 13px 32px;
    margin: 0;
    font-size: 14px;
    color: $white;
  }

  &.btn-branding {
    border-radius: 0 12px 12px 12px;
    text-transform: unset;
    text-align: left;
    font-size: 16px;
    height: 65px;

    &.disabled {
      pointer-events: none;
    }
  }
}

.btn-branding-wrapper {
  .btn {
    height: 65px;
    border-radius: 0 12px 12px 12px;
    text-transform: unset;
    text-align: left;
    font-size: 16px;
  }
}

.btn-onboarding-secondary {
  @include button-color(#1c1c1a);
  @include button-variant(#dee5e5, #dee5e5);
  @include button-color-hover(#1c1c1a);
  @include button-color-active(#1c1c1a);
}
