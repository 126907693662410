.icon-selector {
  height: 48px;
  border: 1px solid $gray-300;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;

  .icon-tray {
    position: absolute;
    left: 0;
    bottom: 0;
    border: 1px solid #ccc;
    padding: 7px;
    background-color: white;
    z-index: 101;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(170, 170, 170, 0.5);
    max-width: 273px;
    transform: translateY(calc(100% + 5px));
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  .icon-button {
    display: inline-block;
    margin: 2px;

    &.selected-icon {
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      margin: 0;

      > span {
        margin-left: 15px;
        color: $black;
        font-weight: 600;
      }
    }

    button {
      padding: 2px 5px 3px;
    }
  }
}
