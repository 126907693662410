.cart {
  button {
    position: relative;
  }

  svg {
    position: relative;
    top: 2px;
    left: -5px;
  }

  .badge {
    position: absolute;
    top: 3px;
    right: 5px;
    font-size: 16px;
  }
}
