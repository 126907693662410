.chart {
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;

  canvas {
    max-width: 100%;
  }
}
