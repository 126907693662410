.main {
  display: flex;
  width: 100%;
  min-height: 100vh;
  min-width: 0;
  flex-direction: column;
  background-color: $white;
  letter-spacing: 0.02em;
  z-index: 2;
  position: relative;
  border-left: 2px solid #eceff1;

  @include media-breakpoint-down(md) {
    overflow-y: hidden;
  }
}
