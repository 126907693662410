.select-small-container {
  .react-select__control {
    min-height: auto;
    position: relative;
    top: 4px;
    border: none;

    &--is-focused,
    &--is-menu-open {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color !important;
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    }
  }

  .react-select__value-container {
    padding: $input-padding-y $input-padding-x;

    > div {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
    }
  }

  .react-select__single-value {
    position: initial;
    overflow: initial;
    transform: none;
  }

  .react-select__indicators:after {
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    display: none;
  }

  .react-select__option {
    padding-bottom: $input-padding-y;
    padding-top: $input-padding-y;
    cursor: pointer;
  }

  .react-select__multi-value__remove:hover {
    background: transparent;
    color: $black;
    cursor: pointer;
  }
}
