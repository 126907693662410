.tab {
  .nav-tabs {
    padding-bottom: 15px;
    margin-bottom: 40px;

    .nav-item {
      position: relative;

      .nav-link {
        padding: 0 20px;
        font-size: 16px;
        line-height: 24px;
        color: $gray-600;
        font-weight: normal;
        border: none;

        &.active {
          background-color: unset;
          color: $primary;
        }

        &:hover {
          border: none;
        }
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .tab-#{$color} {
    @include tab-variant($value);
  }
}

.tab-title {
  font-size: $font-size-lg;
}

.tab-vertical .nav-tabs {
  float: left;
  flex-direction: column;

  .nav-link {
    @include border-left-radius($border-radius);
    @include border-right-radius(0);
  }
}

.tab-vertical .tab-content {
  overflow: auto;
}
