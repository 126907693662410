.card {
  margin-bottom: 2rem;
  background-color: $gray-100;

  .card-body {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.card.full {
  height: 100%;
  width: 100%;
}

.card {
  &:hover {
    .hidden-hover {
      display: inherit;
    }
  }

  .hidden-hover {
    display: none;
  }
}

.card-header {
  padding: 0 0 16px;
}

.card-actions {
  a {
    color: $body-color;
    text-decoration: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  .dropdown {
    line-height: 1.4;
  }
}

.card-title {
  font-size: $font-size-lg;
  font-weight: $card-title-font-weight;
  color: $card-title-color;
}

.card-subtitle {
  font-weight: $font-weight-normal;
}

.card-table {
  margin-bottom: 0;
}

.card-table tr td:first-child,
.card-table tr th:first-child {
  padding-left: $card-spacer-x;
}

.card-table tr td:last-child,
.card-table tr th:last-child {
  padding-right: $card-spacer-x;
}

.card-img,
.card-img-top,
.card-img-bottom {
  @include img-fluid;

  @media all and (-ms-high-contrast: none) {
    height: 100%;
  }
}

.card.selectable {
  cursor: pointer;

  border: 2px solid white;

  &:hover {
    border: 2px solid lighten($primary, 30%);
  }

  &.selected {
    border: 2px solid $primary;
  }
}

.card {
  .draggable:hover {
    cursor: move;
  }
}
