.wrapper {
  align-items: stretch;
  display: flex;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

.wrapper-boxed {
  max-width: 1440px;
  margin: 0 auto;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.widget-wrapper {
  width: 100%;
  height: 100%;
  background-color: $gray-100;
  border-radius: 8px;

  &.p-10 {
    padding: 10px;
  }

  &.p-15 {
    padding: 15px;
  }

  &.map-widget-wrapper {
    > div:first-child {
      border-radius: 8px;
      overflow: hidden;
    }
  }

  table {
    height: fit-content;

    tr {
      td {
        .legend-circle {
          display: inline-block;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}
