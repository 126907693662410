.ribbon-parent {
  overflow: hidden;
}

.ribbon {
  margin: 0;
  background: #47bac1;
  color: white;
  padding: 10px 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top: 0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: #47bac1;
}
.ribbon:before {
  right: 100%;
}

.ribbon:after {
  left: 100%;
}
