.report {
  background-color: $white;

  .card-header {
    .name {
      margin-top: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }

  .card-body {
    padding: 0;
  }

  &:hover .card-header .name {
    width: calc(100% - 80px);
  }
}
