.tree {
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;

  .rst__lineBlock {
    display: none;
  }

  .rst__node {
    display: flex;

    .rst__lineBlock {
      flex: 0 0 auto;
    }

    .rst__nodeContent {
      left: auto !important;
      top: auto !important;
      position: relative !important;
      flex: 0 1 auto;
      width: 100%;
    }
  }

  .bar-wrapper {
    margin-top: 5px;
    margin-left: 5px;
  }

  .previous {
    margin-left: 10px;
  }

  .next,
  .collapse {
    margin-right: 10px;
    margin-left: 5px;
  }

  .tree-item-base {
    height: 100%;
    align-items: center;
    width: 100%;

    &.device-hover {
      background-color: $primary;
    }

    &.device-droppable {
      padding: 0;
      margin-top: 1px;
      border: 1px dotted $primary;
    }
  }

  .tree-item {
    height: 100%;
    transition: background-color 0.15s ease-in-out;
    margin-bottom: 10px;

    svg {
      flex-shrink: 0;
    }

    .tree-item-icon {
      height: 15px;
      width: 15px;
      margin-right: 10px;
    }

    &:hover {
      background-color: $gray-300;

      .tree-icon-location {
        * {
          fill: $gray-500;
        }
      }

      .tree-icon-folder {
        * {
          fill: $gray-500;
        }
      }
    }

    padding: 2px 0;

    &.selected {
      background-color: $gray-400;
      color: $gray-800;
      font-weight: 400;

      .tree-icon-location {
        * {
          fill: $gray-600;
        }
      }

      .tree-icon-folder {
        * {
          fill: $gray-600;
        }
      }
    }

    &.error {
      background-color: $red;
      color: white;
    }

    &.search-focus {
      border: 1px solid $red;

      .tree-node-content {
        font-weight: 500;
        color: $dark;
      }
    }

    &.search-match {
      border: 1px dashed $red;
    }

    &.search-disabled {
      opacity: 0.5;
    }

    .selected {
      font-weight: bold;
    }

    &.selectable {
      cursor: pointer;
    }

    cursor: default;

    .toggler {
      width: 20px;
      display: inline-block;
      cursor: pointer;
      flex-shrink: 0;
    }

    .spacer {
      display: inline-block;
      flex-shrink: 0;
    }
  }

  .tree-item {
    .tree-buttons {
      margin-left: 5px;
      float: right;
      position: relative;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.15s ease-in-out;
      flex-shrink: 0;
    }

    &:hover {
      .tree-buttons {
        opacity: 1;
        pointer-events: unset;
      }
    }
  }
}
