.modal {
  .modal-dialog {
    max-width: 750px;
    background-color: transparent;

    .modal-content {
      border-radius: 4px;
      background-color: $white;
      box-shadow: 0 16px 24px rgba(0, 0, 0, 0.15), 0 2px 6px rgba(0, 0, 0, 0.15),
        0 0 1px rgba(0, 0, 0, 0.15) !important;

      .modal-header {
        border-radius: 0;
        padding: 15px 35px;
        display: flex;
        align-items: center;
        border-bottom-color: $info;
        border-bottom-width: 2px;
        background-color: $info;
        box-shadow: 0 16px 24px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.1),
          0 0 1px rgba(0, 0, 0, 0.1) !important;

        .modal-title {
          font-size: 30px;
          font-weight: normal;
          color: $white !important;

          > div,
          .text-muted {
            color: $white !important;
          }
        }

        button {
          top: 10px;
          right: 10px;
          font-size: 20px;
          position: absolute;
          margin: 0;
          padding: 0;
          width: 30px;
          height: 30px;
          background-color: $white;
          border: 2px solid $info;
          color: $info;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          opacity: 1;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

          &:hover {
            background-color: $info;
            color: $white;
            border-color: $white;
          }
        }
      }

      .modal-body {
        padding: 40px 35px;
        margin: 0 !important;

        .row {
          margin-bottom: 1rem;
        }

        select {
          height: 45px;
          border-color: $gray-300;
          border-radius: 4px;
        }

        textarea {
          border-color: $gray-300;
          border-radius: 4px;
        }
      }

      .modal-footer {
        border-top: 1px solid $gray-300;
        padding: 20px 35px;

        > div {
          margin: 0;
        }

        .btn {
          min-width: 160px;
          border-radius: 4px;
          margin-right: 0 !important;
          padding: 10px;

          &:not(:last-of-type) {
            margin-right: 15px !important;
          }

          &.btn-primary {
            background-color: $info;
            border: none !important;
          }

          &.btn-light,
          &.btn-secondary {
            background-color: $white;
            border-color: $gray-400 !important;
            color: $dark;

            &:hover {
              background-color: $gray-150;
            }

            &:focus,
            &:active {
              box-shadow: 0 0 0 1px $gray-400 !important;
            }
          }
        }
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .modal-#{$color} {
    .modal-content {
      .modal-header {
        border-bottom-color: $value !important;
        background-color: $value !important;

        button {
          border: 2px solid $value !important;
          color: $value !important;

          &:hover {
            background-color: $value !important;
            border-color: $white !important;
            color: $white !important;
          }
        }
      }

      .btn-primary {
        background-color: $value !important;

        &:focus,
        &:active {
          box-shadow: 0 0 0 1px $value !important;
        }

        &:hover {
          background-color: darken($value, 10) !important;
        }
      }
    }
  }
}
