.api-details {
  padding: 16px;

  & > div {
    margin-top: 15px;
    margin-bottom: 25px;

    & > h5 {
      margin-bottom: 15px;
    }
  }
}
