.wizard {
  $arrow-size: 21px;
  $arrow-width: 11px;
  $item-height: 40px;

  @mixin triangle($color) {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: $arrow-size solid transparent;
    border-bottom: $arrow-size solid transparent;
    border-left: $arrow-width solid $color;
    position: absolute;
    top: 50%;
    margin-top: -$arrow-size;
    left: 100%;
    z-index: 2;
  }

  button {
    border: none;
  }

  .step-anchor {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
  }

  li {
    list-style: none;
  }

  .step {
    height: $item-height;
    color: #737d85;
    padding: 10px 15px 10px 30px;
    position: relative;

    background: inherit;

    &:focus {
      outline: 0;
    }

    &.enabled {
      background: lighten($primary, 15%);

      color: #fff;

      &:before {
        border-left-color: lighten($primary, 15%);
      }
    }

    &.active {
      background-color: $primary;
      color: #fff;

      &:before {
        border-left-color: $primary;
      }
    }
  }

  .step:before {
    @include triangle(#fff);
    z-index: 2;
  }

  .step:after {
    @include triangle(#ddd);
    margin-left: 1px;
    z-index: 1;
  }
}
