.geosuggest__item {
  &.geosuggest__item--active {
    background: $primary;
    color: white;

    &:hover {
      background: $primary;
    }
  }

  &:hover {
    background-color: rgba(255, 247, 0, 0.13);
  }

  cursor: pointer;
}

.geosuggest__suggests {
  &.geosuggest__suggests--hidden {
    display: none;
  }

  padding: 10px;
  list-style: none;
  margin-left: 0;
}
