body,
html,
#root {
  height: 100%;
}

body {
  overflow-y: auto;
  opacity: 1 !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow-x: hidden;
  }
}
