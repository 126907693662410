.content {
  padding: $content-padding;
  flex: 1;

  @include media-breakpoint-down(md) {
    width: 100vw;
    max-width: 100vw;
  }

  @include media-breakpoint-down(lg) {
    padding: $spacer * 1.5;
  }

  .page-title {
    font-size: 25px;
    line-height: 52px;
    color: $dark;
    letter-spacing: 0.01em;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    > a {
      color: $gray-600 !important;
      transition: color 0.15s ease-in-out !important;

      &:hover {
        color: $dark !important;
      }
    }

    .page-title-icon-wrapper {
      padding: 10px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      height: 100%;
      color: $white;
      border-radius: 50%;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
