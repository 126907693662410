.badge {
  color: $white;
  padding: 5px 4px 4px;
}

.inverted .badge {
  @each $color, $value in $theme-colors {
    &.badge-#{$color} {
      color: $value;
      background-color: $white;
    }
  }
}

.inverted .badge {
  @each $color, $value in $theme-colors {
    &.badge-#{$color} {
      color: $value;
      background-color: $white;
    }
  }
}
