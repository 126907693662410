.gauge {
  .gauge-details {
    margin: 0.5rem;
    text-align: center;

    > h5 {
      display: inline;
    }

    > span {
      color: #6c757d;
      width: 20%;

      &:first-child {
        float: left;
      }

      &:last-child {
        float: right;
      }
    }
  }
}
