.card {
  > .dataTables_wrapper .table.dataTable,
  > .table,
  > .table-responsive .table,
  > .table-responsive-sm .table,
  > .table-responsive-md .table,
  > .table-responsive-lg .table,
  > .table-responsive-xl .table {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;

    td:first-child,
    th:first-child {
      border-left: 0;
      padding-left: $spacer * 1.25;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
      padding-right: $spacer * 1.25;
    }

    tr:first-child td,
    tr:first-child th {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }

  .card-header + .table {
    border-top: 0;
  }
}

table {
  tr:first-child td,
  tr:first-child th {
    border-top: 0;
  }
}

.table-action {
  a {
    color: $gray-600;

    &:hover {
      color: $gray-900;
    }
  }

  .feather {
    width: 18px;
    height: 18px;
  }
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.card > .dataTables_wrapper {
  .table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .dataTables_info {
    padding: 1rem 1.25rem;
  }

  .dataTables_paginate {
    padding: 0.6rem 1.25rem;
  }
}

.dt-bootstrap4 {
  width: calc(100% - 2px);
}

.table {
  &:not(.table-sm, .default-table) {
    border: 1px solid $gray-200;

    thead,
    .thead {
      background-color: $gray-100;
      height: fit-content;

      tr,
      .tr {
        border-bottom: none;
        border-top: none;

        th,
        .th {
          text-transform: uppercase;
          letter-spacing: 0.2em;
          line-height: 16px;
          font-size: 11px;
          padding: 20px 15px 10px;
          background-color: $gray-100;
          border: none;
        }
      }
    }

    tbody,
    .tbody {
      tr,
      .tr {
        border-bottom: 1px solid $gray-200;

        &:last-of-type {
          border-bottom: none;
        }

        td,
        .td {
          border: none;
          padding: 20px 15px;
          font-size: 14px;
          letter-spacing: 0.02em;
        }
      }
    }
  }

  .tbody {
    .tr {
      display: flex;
      align-items: center;
    }
  }
}

.table-sm {
  &.dashboard-widget {
    thead {
      tr {
        th {
          color: $gray-600;
          text-transform: uppercase;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.2em;
          border-bottom: none !important;
          padding: 15px;
        }
      }
    }

    tbody {
      tr {
        &:not(:last-of-type) {
          border-bottom: 1px solid $gray-150;
        }

        td {
          padding: 10px 5px;
          border: none;
        }
      }
    }

    margin-bottom: 0;
    border-radius: 8px;
    background-color: $gray-100;

    tr {
      height: 40px;
    }

    tbody {
      tr {
        &:not(:last-of-type) {
          border-bottom: 1px solid $gray-200;
        }

        td {
          padding: 10px 15px;
        }
      }
    }
  }
}
