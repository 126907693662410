.cluster-marker {
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  border: 1px solid white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;

  &:hover {
    border-color: gray;
  }

  &.max-zoom {
    cursor: not-allowed;
  }
}

.incident-marker {
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  border: 1px solid white;
  padding-top: 7px;
  text-align: center;
  height: 30px;
  width: 30px;

  &:hover {
    border-color: gray;
  }
}
